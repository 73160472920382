import { SVGProps } from "react";

export default function IndustriesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.97436 0L6.57853 1.37021L9.73558 3.14103L9.23878 4.03046L5.55289 1.95513L3.6218 3.0609L6.65064 4.67149L6.16987 5.57692L2.57212 3.66184L0.745192 4.70354L3.57372 6.20995L3.09295 7.11539L0 5.46474V15.3846L3.33333 16.9231V11.2821L6.15385 12.8205V18.4615L9.23077 20L20 14.6154V5.38462L8.97436 0ZM17.9487 8.97436V10.7692L16.4103 11.5385V9.48718L17.9487 8.97436ZM15.3846 10V11.7949L13.8462 12.5641V10.5128L15.3846 10ZM12.8205 11.0256V12.8205L11.2821 13.5897V11.5385L12.8205 11.0256ZM17.9487 12.0513V13.8462L16.4103 14.6154V12.5641L17.9487 12.0513ZM15.3846 13.0769V14.8718L13.8462 15.641V13.5897L15.3846 13.0769ZM12.8205 14.1026V15.8974L11.2821 16.6667V14.6154L12.8205 14.1026Z"
        fill="#442873"
      />
    </svg>
  );
}
