import * as React from "react";
import { SVGProps } from "react";
const DropdownContinueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_12808_155220)">
      <path
        d="M14.5808 7.27381L2.17871 0.113545C1.61974 -0.209036 0.920898 0.194061 0.920898 0.839739V15.1603C0.920898 15.8059 1.61974 16.209 2.17871 15.8865L14.5808 8.72619C15.1397 8.40361 15.1397 7.59639 14.5808 7.27381Z"
        fill="#442873"
      />
    </g>
    <defs>
      <clipPath id="clip0_12808_155220">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DropdownContinueIcon;
