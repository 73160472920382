import * as React from "react";
import { SVGProps } from "react";

const DropdownDeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={16}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.2002 5.6C8.09512 5.60006 7.99106 5.57941 7.89397 5.53923C7.79688 5.49905 7.70867 5.44013 7.63437 5.36583C7.56007 5.29153 7.50114 5.20331 7.46096 5.10622C7.42078 5.00913 7.40013 4.90508 7.4002 4.8V1.44186e-07H1.0002C0.89512 -6.29578e-05 0.791063 0.0205867 0.693974 0.0607681C0.596885 0.100949 0.508669 0.159874 0.434369 0.234174C0.36007 0.308473 0.301145 0.39669 0.260963 0.493779C0.220782 0.590868 0.200132 0.694925 0.200195 0.8V15.2C0.200132 15.3051 0.220782 15.4091 0.260963 15.5062C0.301145 15.6033 0.36007 15.6915 0.434369 15.7658C0.508669 15.8401 0.596885 15.8991 0.693974 15.9392C0.791063 15.9794 0.89512 16.0001 1.0002 16H12.2002C12.3053 16.0001 12.4093 15.9794 12.5064 15.9392C12.6035 15.8991 12.6917 15.8401 12.766 15.7658C12.8403 15.6915 12.8992 15.6033 12.9394 15.5062C12.9796 15.4091 13.0003 15.3051 13.0002 15.2V5.6H8.2002ZM10.3658 10.9656L7.4002 13.9313L6.26892 12.8L7.86892 11.2H2.6002V9.6H7.86892L6.26892 8L7.4002 6.86872L10.3658 9.83432C10.5158 9.98435 10.6001 10.1878 10.6001 10.4C10.6001 10.6121 10.5158 10.8156 10.3658 10.9656ZM12.5315 4H9.0002V0.46872L12.5315 4Z"
      fill="#442873"
    />
  </svg>
);
export default DropdownDeleteIcon;
