import { SVGProps } from "react";

export default function DraftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M19.9999 13.4904C20.0022 13.7387 19.9471 13.9827 19.8409 14.1944C19.7348 14.4062 19.5819 14.5769 19.3999 14.6871L11.3282 19.6225C10.9152 19.8728 10.4605 20.0029 9.99956 20.0029C9.53864 20.0029 9.08395 19.8728 8.67094 19.6225L0.599208 14.7133C0.418969 14.6017 0.267552 14.4312 0.161775 14.2207C0.0559981 14.0102 0 13.768 0 13.5209C0 13.2739 0.0559981 13.0317 0.161775 12.8212C0.267552 12.6107 0.418969 12.4402 0.599208 12.3286L2.67786 11.0619L7.97806 14.3115C8.60684 14.6906 9.29851 14.8877 9.99956 14.8877C10.7006 14.8877 11.3923 14.6906 12.0211 14.3115L17.3284 11.0619L19.3999 12.3286C19.5776 12.4361 19.7276 12.6014 19.8334 12.8064C19.9393 13.0113 19.9969 13.248 19.9999 13.4904ZM0.599208 7.66392L8.67094 12.6168C9.08395 12.867 9.53864 12.9972 9.99956 12.9972C10.4605 12.9972 10.9152 12.867 11.3282 12.6168L19.3999 7.68139C19.5802 7.56979 19.7316 7.39928 19.8373 7.18879C19.9431 6.9783 19.9991 6.73608 19.9991 6.48902C19.9991 6.24197 19.9431 5.99975 19.8373 5.78926C19.7316 5.57877 19.5802 5.40826 19.3999 5.29666L11.3282 0.387424C10.9158 0.134586 10.4609 0.00292969 9.99956 0.00292969C9.53825 0.00292969 9.08332 0.134586 8.67094 0.387424L0.599208 5.27919C0.418969 5.39079 0.267552 5.5613 0.161775 5.77179C0.0559981 5.98228 0 6.2245 0 6.47155C0 6.71861 0.0559981 6.96083 0.161775 7.17132C0.267552 7.38181 0.418969 7.55232 0.599208 7.66392Z"
        fill="currentColor"
      />
    </svg>
  );
}
