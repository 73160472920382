import * as React from "react";
import { SVGProps } from "react";

const DropdownDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.8 6.3998H12.8L8 11.1998L3.2 6.3998H7.2V0.799805H8.8V6.3998ZM1.6 13.5998H14.4V7.9998H16V14.3998C16 14.8798 15.68 15.1998 15.2 15.1998H0.8C0.32 15.1998 0 14.8798 0 14.3998V7.9998H1.6V13.5998Z"
      fill="#442873"
    />
  </svg>
);
export default DropdownDownloadIcon;
